<template>
  <button type="button" @click="$emit('click')" :class="['btn product-button', {'center':centerText}]">
    <div>{{formatTitle(title)}}</div>
    <img v-if="icon" :src="getIcon(icon)" :class="['button__image']" />
  </button>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    centerText:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    getIcon(icon) {
      return require('@/assets/images/' + icon);
    },
    formatTitle(title) {
      if (title.length > 20) {
        //   title.slice(0, 20)+'...';
        return title;
      } else return title;
    }
  }
};
</script>

<style lang="scss" scoped>
.center{
  display:flex;
  justify-content: center;
  align-items: center;
}
.product-button {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  padding: 5px 11.25px !important;
  background-color: white;
  height: 38px !important;
  border-radius: 0;
  white-space: nowrap;
  font-weight: 400;
  color: black;

  // @include respond(laptop-small){
  //   font-size: 14px;
  // }
  img{
    max-width: 17px;
  }
}

.btn{
  text-transform: none !important;
}


</style>
