<template>
  <div>

    <div v-if="exhibit" :class="['popup-container' ,inWindow==false ? 'overlay' : 'behind']">

      <div v-if="exhibit.is_video_only" class="video__popup">
        <div class="close-container">
        <button
          v-if="inWindow==false"
          type="button"
          class="close"
          aria-label="Close"
          @click="closeProduct"
        >
          <span aria-hidden="true">              
            <img src="@/assets/images/Icon-Close-red.svg" alt="" class="close--icon" style="width: 35px; margin-bottom: -50px; background-color: rgba(255,255,255,0.9); padding: 7px;">
          </span>
        </button>
      </div>
        <video controls height="365" class="video--full" autoplay playsinline>
          <source :src="videos[0].path" />
        </video>

      </div>
      
      
      <div v-if="!exhibit.is_video_only" class="popup">
        <div class="close-container">
          <button
            v-if="inWindow==false"
            type="button"
            class="close"
            aria-label="Close"
            @click="closeProduct"
          >
            <span aria-hidden="true">              
              <img src="@/assets/images/Icon-Close.svg" alt="" class="close--icon">
            </span>
          </button>
        </div>
        <div class="content">
          <div class="row p-0 m-0">
            <div class="col-6 p-0 m-0 left-section">
              <h1 v-html="exhibit.title" class="product-title"></h1>
              <HaefeleButton
                v-if="hasVR"
                @click="goToVR(exhibit.files['3d-objects'])"
                :title="'Jetzt virtuell entdecken'"
                :centerText="true"
                :icon="''"
              />
              <p v-html="exhibit.short_text" class="product-body"></p>
              <div v-if="productRegister()" class="product__buttonGrid">
                <HaefeleButton
                  @click="openNotes()"
                  :title="$t('views.buttons.add_to_watchlist')"
                  :icon="inFavorites? 'Icon-Done.svg': 'Icon-Notes.svg'"
                />
                <HaefeleButton
                  v-if="exhibit.files && exhibit.files['links'].length != 0"
                  @click="goToShop(exhibit.files.links)"
                  :title="$t('views.buttons.go_to_shop')"
                  :icon="'Icon-Haefele.svg'"
                />
                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
                <HaefeleButton
                  v-if="files['documents'].length != 0"
                  @click="downloadFile()"
                  :title="$t('views.buttons.download_pdf')"
                  :icon="'Icon-download.svg'"
                />
              </div>
            </div>
            <div class="col-6 p-0 m-0 right-section">
              <img
                :src="files['cover-image'].path"
                :alt="files['cover-image'].filename"
                class="product-image"
              />
            </div>
          </div>
          <div v-if="exhibit.files.images.length > 0">
            <div class="row p-0 section__padding">
              <div class="long-text-title">{{$t('views.product_description')}}</div>
              <p
                v-if="exhibit.files.images[selectedImage].text"
                v-html="exhibit.files.images[selectedImage].text"
                class="long-text w-100"
              ></p>
            </div>
            <div v-if="images.length > 0" class="slider">
              <hooper
                class="hooper-1"
                ref="hooper"
                group="group1"
                :wheelControl="false"
                :infiniteScroll="true"
                @slide="imageSwipe"
              >
                <slide
                  v-for="(image) in images"
                  :key="image.filename"
                  class="d-flex justify-content-center"
                >
                  <img :src="image.path" :alt="image.filename" class="big-slider-image" />
                </slide>
                <hooper-navigation slot="hooper-addons">
                  <i slot="hooper-prev" class="fas fa-angle-left arrow"></i>
                  <i slot="hooper-next" class="fas fa-angle-right arrow"></i>
                </hooper-navigation>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>
              </hooper>

              <div class="row p-0 m-0">
                <div class="d-flex flex-md-wrap small-slider">
                  <img
                    v-for="(image, index) in images"
                    :key="index"
                    :src="image.path"
                    :alt="image.filename"
                    @click="goToImage(index)"
                    :class="['small-slider-image', index==selectedImage ? 'selected-image' : '']"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="exhibit.files.videos[selectedVideo]"
            class="row p-0 product__video--description"
          >
            <div class="long-text-title">{{$t('views.product_video')}}</div>
            <p
              v-if="exhibit.files.videos[selectedVideo].text"
              v-html="exhibit.files.videos[selectedVideo].text"
              class="long-text w-100"
            ></p>
          </div>
          <div v-if="videos.length > 0" class="slider-2">
            <hooper
              class="hooper-2"
              ref="hooper2"
              group="group2"
              :wheelControl="false"
              :infiniteScroll="true"
            >
              <slide
                v-for="(video) in videos"
                :key="video.filename"
                class="d-flex justify-content-center"
              >
                <video controls height="365" class="red" playsinline>
                  <source :src="video.path" />
                </video>
              </slide>
              <hooper-navigation slot="hooper-addons">
                <i slot="hooper-prev" class="fas fa-angle-left arrow"></i>
                <i slot="hooper-next" class="fas fa-angle-right arrow"></i>
              </hooper-navigation>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </div>

          <!-- <div v-if="this.files['3d-objects'].length > 0" class="row p-0 m-0">
            <div class="threeD-title">{{$t('views.threeD_model')}}</div>
            <div ref="container" class="threeD"></div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'hooper/dist/hooper.css';
import HaefeleButton from '@/components/shared/Button';
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
// import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default {
  components: {
    Hooper,
    Slide,
    HaefeleButton,
    HooperPagination,
    HooperNavigation
  },
  props: {
    showPopup: Boolean,
    closeProduct: Function,
    openPopup: Function,
    productId: String,
    inWindow: Boolean,
    toggleShowChat: Function,
    setSelectedUser: Function,
    selectedNote: String,
    setSelectedExhibit: Function,
    goToNotes: Function,
    toggleShowProduct: Function
  },
  watch:{
    exhibit(newValue,oldValue){
      console.log(oldValue,newValue);
      if(newValue!==null && newValue.files){
        let vrArrayLinks = newValue.files['3d-objects'];
        this.hasVR = vrArrayLinks[0]==null ? false:true;
      }
      return newValue;
    }
  },
  data() {
    return {
      hasVR:false,
      exhibit: null,
      images: [],
      selectedImage: 0,
      selectedVideo: 0,
      files: [],
      inFavorites: false,
      videos: [],
      auth_state: this.$store.getters.isAuthorized
    };
  },
  beforeMount() {
    this.getExhibit();
  },
  computed: {},
  methods: {
    async getExhibit() {
      var url = (await this.$store.getters.isAuthorized)
        ? '/exhibit'
        : '/shared-exhibit';
      this.$nextTick(async () => {
        let resp = await this.$http.get(url, {
          params: { uuid: this.productId }
        });
        this.exhibit = resp.data;
        this.files = this.exhibit.files;
        if (this.files) {
          this.images = this.files.images;
          this.videos = this.files.videos;
        }
        this.$store.getters.isAuthorized ? this.getNotes() : '';
      });
    },

    getNotes() {
      this.$http
        .get('/notes')
        .then(resp => {
          this.checkFavorites(resp.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    checkFavorites(notes) {
      for (var i = 0; i < notes.length; i++) {
        if (notes[i].exhibit_uuid == this.exhibit.uuid) {
          this.inFavorites = true;
        }
      }
    },
    productRegister() {
      if (!this.$store.getters.isAuthorized && this.$route.name == 'ProductWindow') {
        return false;
      } else{
        return true;
      }
    },
    imageSwipe(payload) {
      if (payload.currentSlide == this.images.length) {
        this.selectedImage = 0;
      } else if (payload.currentSlide == -1) {
        this.selectedImage = this.images.length - 1;
      } else {
        this.selectedImage = payload.currentSlide;
      }
    },
    videosSwipe(payload) {
      if (payload.currentSlide == this.images.length) {
        this.selectedVideo = 0;
      } else if (payload.currentSlide == -1) {
        this.selectedVideo = this.images.length - 1;
      } else {
        this.selectedImage = payload.currentSlide;
      }
    },
    // init() {
    //   this.$nextTick(() => {
    //     const container = this.$refs.container;

    //     var renderer = new THREE.WebGLRenderer({ container });
    //     container.appendChild(renderer.domElement);
    //     renderer.setSize(container.clientWidth, container.clientHeight, false);

    //     const fov = 45;
    //     const aspect = 2; // the canvas default
    //     const near = 0.1;
    //     const far = 100;
    //     const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    //     camera.position.set(0, 10, 20);

    //     const controls = new OrbitControls(camera, container);
    //     controls.target.set(0, 5, 0);
    //     controls.update();

    //     const scene = new THREE.Scene();

    //     //light
    //     {
    //       const intensity = 1;
    //       const light = new THREE.HemisphereLight(intensity);
    //       scene.add(light);
    //     }

    //     {
    //       const color = 0xffffff;
    //       const intensity = 1;
    //       const light = new THREE.DirectionalLight(color, intensity);
    //       light.position.set(5, 10, 2);
    //       scene.add(light);
    //       scene.add(light.target);
    //     }

    //     var frameArea = function(
    //       sizeToFitOnScreen,
    //       boxSize,
    //       boxCenter,
    //       camera
    //     ) {
    //       const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
    //       const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
    //       const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
    //       // compute a unit vector that points in the direction the camera is now
    //       // in the xz plane from the center of the box
    //       const direction = new THREE.Vector3()
    //         .subVectors(camera.position, boxCenter)
    //         .multiply(new THREE.Vector3(1, 0, 1))
    //         .normalize();

    //       // move the camera to a position distance units way from the center
    //       // in whatever direction the camera was from the center already
    //       camera.position.copy(
    //         direction.multiplyScalar(distance).add(boxCenter)
    //       );

    //       // pick some near and far values for the frustum that
    //       // will contain the box.
    //       camera.near = boxSize / 100;
    //       camera.far = boxSize * 100;

    //       camera.updateProjectionMatrix();

    //       // point the camera to look at the center of the box
    //       camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
    //     };

    //     {
    //       const gltfLoader = new GLTFLoader();
    //       gltfLoader.load(
    //         this.files['3d-objects'][0].path +
    //           this.files['3d-objects'][0].filename,
    //         gltf => {
    //           const root = gltf.scene;
    //           scene.add(root);

    //           // compute the box that contains all the stuff
    //           // from root and below
    //           const box = new THREE.Box3().setFromObject(root);

    //           const boxSize = box.getSize(new THREE.Vector3()).length();
    //           const boxCenter = box.getCenter(new THREE.Vector3());

    //           // set the camera to frame the box
    //           frameArea(boxSize * 0.5, boxSize, boxCenter, camera);

    //           // update the Trackball controls to handle the new size
    //           controls.maxDistance = boxSize * 10;
    //           controls.target.copy(boxCenter);
    //           controls.update();
    //         }
    //       );
    //     }

    //     var render = function() {
    //       renderer.render(scene, camera);

    //       requestAnimationFrame(render);
    //     };

    //     requestAnimationFrame(render);
    //   });
    // },
    downloadFile() {
      this.$http
        .get(this.files.documents[0].path, {responseType: 'arraybuffer'})
        .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.files.documents[0].filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.log(err);
        });
    },
    goToImage(index) {
      this.$refs.hooper.slideTo(index);
      this.selectedImage = index;
    },
    goToVideo(index) {
      this.$refs.hooper.slideTo(index);
      this.selcetedVideo = index;
    },
    openNotes() {
      this.closeProduct();
      this.setSelectedExhibit(this.exhibit);
      this.goToNotes();
    },
    goToShop(links) {
      const url = links[0];
      var win = window.open(url, '_blank');
      win.focus();
    },
    goToVR(path) {
      const url = path[0];
      var win = window.open(url, '_blank');
      win.focus();
    },
    buttonVisibility() {
      if (!this.$store.getters.isAuthorized) {
        return 'hide';
      }
    },
    async requestLiveChat() {
      this.closeProduct();
      if (this.$store.getters.isAuthorized) {
        if (
          localStorage.getItem('user-roles').includes('exhibitor') ||
          localStorage.getItem('user-roles').includes('organizer')
        ) {
          this.$router.push('/exhibitor/' + this.exhibit.contact);
        } else {
          await this.$http.post(
            'distributor-call',
            JSON.stringify({ customer: this.$store.state.user.uuid })
          );
          this.$store.state.modalMessage = 'Chat wurde angefragt. Ein Mitarbeiter meldet sich in Kürze.';
          this.$store.state.modalIcon = 'fas fa-check-circle';
          this.$store.state.modalClass = 'green-text';
          this.$store.state.modal = true;
          setTimeout(
            function() {
              this.$store.state.modal = false;
            }.bind(this),
            5000
          );
        }
      } else {
        window.location.assign(
          `mailto:${this.exhibit.contact}?Subject=${this.exhibit.title}`
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-container {
  height: 90%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  z-index: 119;
}

.behind {
  z-index: -1;
}

.hide {
  display: none !important;
}

.popup {
  background-color: $haefeleGrey;
  width: 70%;
  height: 94%;
  margin-top: 20% !important;
  z-index: 110;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 5%);
  margin: 0 !important;
  padding-left: 34px;
  padding-right: 34px;
  padding-bottom: 50px;
}


.video{
  &__popup{
    width: 70%;
    height: 80%;
    max-width: 1000px;
    z-index: 110;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 5%);
    margin: 0 !important;
  }

&--full{
  width: 100%;
  height: auto;
}
}
.content {
  overflow-y: scroll;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  min-height: 350px;
  height: 90%;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.close-container {
  width: 100% !important;
  position: relative;
  min-height: 50px;
}
.close {
  font-size: 20px;
  color: white;
  opacity: 1 !important;
  font-weight: 100;
  padding-bottom: 25px;
  padding-top: 25px;

  &--icon{
    width: 14px;
  }
}

.product-title {
  letter-spacing: 1.2px;
  color: $secondaryColor;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 40px;
}

.product-body {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.49px;
  color: #ffffff;
  padding-bottom: 40px;
  margin-top: 25px;
  margin-bottom: 0px !important;
}

.product-button {
  width: 100%;
  background-color: white;
  height: 37px;
  margin: 0 !important;
  border-radius: 0;
  padding-left: 31px !important;
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  text-transform: none;
  justify-content: space-around;

  // @include respond(laptop-small){
  //   font-size: 12px;
  // }
}

.button-content {
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
}

.done-image {
  margin-left: 15px;
  max-width: 15%;
}

.button {
  &__image {
    max-width: 15%;
    text-align: right;
  }
}

.product-button:hover {
  background-color: $secondaryColor;
}

.product-image {
  width: 100%;
}

.section__padding {
  margin-top: 3rem;
  padding-left: 15px !important;
}

.left-section {
  padding-right: 38px !important;
}

.right-section {
  padding-left: 38px !important;
}

.product__video--description {
  padding-left: 15px !important;
}

.long-text-title {
  color: white;
  margin-top: 44px;
  margin-bottom: 21px;
  font-size: 24px;
  font-weight: 400;
}

.long-text {
  color: white;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.49px;
  margin-bottom: 2rem;
}

.hooper-1 {
  height: auto;
}

.big-slider-image {
  // height: 100%;
  width: 100%;
}

.arrow {
  color: $primaryColor;
  font-size: 35px !important;
}

.product {
  &__video {
    &--description {
      margin-top: 5rem;
    }
  }

  &__buttonGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}

.small-slider {
  min-height: 120px;
  width: 100%;
  overflow-x: auto;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
}

.small-slider-image {
  height: 92px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 0px !important;
}

.small-slider-image:hover {
  cursor: pointer;
}

.selected-image {
  border: 3px solid rgba(210, 10, 40, 1);
}

.slider-2 {
  margin-top: 50px;
}

.hooper-2 {
  height: 100% !important;
  width: 100%;
}
.big-video {
  width: 500px;
}

.threeD-title {
  margin-top: 75px;
  margin-bottom: 22px;
  font-size: 24px;
  color: white;
  width: 100%;
  padding-left: 20px;
}

.threeD {
  width: 100% !important;
  height: 550px !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1280px) {
  .done-image {
    margin-left: 8px;
  }

  .popup-container {
    height: 100%;
  }

  .popup {
    height: 78%;
    transform: translate(-50%, 16%);
  }
}

@media screen and (min-width: 1920px) {
  .content {
    padding-left: 50px;
    padding-right: 50px;
  }

  .popup {
    padding-bottom: 100px;
  }

  .close {
    padding-bottom: 57px;
    padding-top: 50px;
  }
}
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .popup {
    width: 90%;
  }

  .popup-body {
    font-size: 10px;
    margin-top: 20px;
  }

  .popup-title {
    font-size: 20px;
    margin-top: 20px;
  }

  .popup-content {
    padding: 10px;
  }

  .close {
    font-size: 15px;
  }
}
</style>
